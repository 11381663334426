import swimmingPool from "../../assets/comodidades/swimming-pool.svg";
import barbecue from "../../assets/comodidades/barbecue.svg";
import deckChair from "../../assets/comodidades/deck-chair.svg";
import energy from "../../assets/comodidades/energy.svg";
import fingerprint from "../../assets/comodidades/fingerprint.svg";
import gym from "../../assets/comodidades/gym.svg";
import sauna from "../../assets/comodidades/sauna.svg";
import securityGuard from "../../assets/comodidades/security-guard.svg";
import usb from "../../assets/comodidades/usb.svg";
import wheelchair from "../../assets/comodidades/wheelchair.svg";
import mountainBike from "../../assets/comodidades/mountain-bike.svg";
import petPlace from "../../assets/comodidades/pet-place.svg";
import salaJogos from "../../assets/comodidades/sala-jogos.svg";
import espacoKids from "../../assets/comodidades/espaco-kids.svg";
import salaJogosAdulto from "../../assets/comodidades/sala-de-jogos-adulto.svg";
import storage from "../../assets/comodidades/storage.svg";
import patryRoom from "../../assets/comodidades/party-room.svg";
import spa from "../../assets/comodidades/spa.svg";
import espacoGourmet from "../../assets/comodidades/espaco-gourmet.svg";
import conesPlayground from "../../assets/comodidades/cones-playground.svg";
import circuitoSeguranca from "../../assets/comodidades/circuito-de-seguranca.svg";
import sacadaGoumert from "../../assets/comodidades/sacada-goumert.svg";

export const comodidades = [
  {
    id: 1,
    comodidadeImg: swimmingPool,
    textSup: "Piscina com borda infinita",
    textSub: "",
  },
  {
    id: 2,
    comodidadeImg: barbecue,
    textSup: "Churrasqueiras Gourmet",
    textSub: "",
  },
  {
    id: 3,
    comodidadeImg: deckChair,
    textSup: "Deck Molhado",
    textSub: "",
  },
  {
    id: 4,
    comodidadeImg: energy,
    textSup: "Gerador Total",
    textSub: "",
  },
  {
    id: 5,
    comodidadeImg: fingerprint,
    textSup: "Fechadura Digital",
    textSub: "",
  },
  {
    id: 6,
    comodidadeImg: gym,
    textSup: "Gym",
    textSub: "",
  },
  {
    id: 7,
    comodidadeImg: sauna,
    textSup: "Sauna",
    textSub: "com vista panorâmica",
  },
  {
    id: Math.random(),
    comodidadeImg: securityGuard,
    textSup: "Guarita Blindada",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: usb,
    textSup: "Tomadas USB",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: wheelchair,
    textSup: "Elevador PCD",
    textSub: "Para acesse à piscina",
  },
  {
    id: Math.random(),
    comodidadeImg: mountainBike,
    textSup: "Bicicletário",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: petPlace,
    textSup: "Pet Place",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: salaJogos,
    textSup: "Salão de Jogos ",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: espacoKids,
    textSup: "Espaço Kids",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: storage,
    textSup: "Storage",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: patryRoom,
    textSup: "Salão de Festas",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: sacadaGoumert,
    textSup: "Sacada Gourmet",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: spa,
    textSup: "Spa",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: espacoGourmet,
    textSup: "Espaço Gourmet",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: conesPlayground,
    textSup: "Playground",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: circuitoSeguranca,
    textSup: "Circuito de Segurança",
    textSub: "",
  },
];
